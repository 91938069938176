import * as actionTypes from './actionTypes';

const toggleDrawerLeft = () => ({
  type: actionTypes.TOGGLE_DRAWER_LEFT,
});

const toggleDrawerRight = () => ({
  type: actionTypes.TOGGLE_DRAWER_RIGHT,
});

export {
  toggleDrawerLeft,
  toggleDrawerRight,
};
