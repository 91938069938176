import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import { ReactComponent as IconChevronUp } from '../assets/icons/ChevronUp.svg';
import { ReactComponent as IconChevronDown } from '../assets/icons/ChevronDown.svg';
import Colors from '../theme/Colors';

type Props = {
  path: string;
  value: any;
  onChange: (path: string, value: any) => void;
};

const ColorField = (props: Props) => {
  const {
    path,
    value,
    onChange,
  } = props;
  const [visible, setVisible] = useState(false);

  return (
    <section className="field">
      <button type="button" onClick={() => setVisible(!visible)}>
        <h2 className="heading-secondary">{path}</h2>
        {
          visible
            ? <IconChevronUp fill={Colors.lightGray} className="icon small" />
            : <IconChevronDown fill={Colors.lightGray} className="icon small" />
        }
      </button>
      {
        visible
          ? (
            <SketchPicker
              color={value}
              onChangeComplete={(color) => onChange(path, color.hex)}
              disableAlpha
              presetColors={[]}
            />
          )
          : null
      }
    </section>
  );
};

export default ColorField;
