import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconChevronUp } from '../assets/icons/ChevronUp.svg';
import { ReactComponent as IconChevronDown } from '../assets/icons/ChevronDown.svg';
import Button from './Button';
import Colors from '../theme/Colors';
import DialogModal from './DialogModal';
import { useSaveChecker } from '../utils/hooks';

type Props = {
  link: {
    title: string;
    body: string;
    url: string;
  };
  index: number;
  updateLink: (index: number, title: string, body: string, url: string) => void;
  removeLink: (index: number) => void;
};

const propTypes = {
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  updateLink: PropTypes.func.isRequired,
  removeLink: PropTypes.func.isRequired,
};

const UsefulLink = (props: Props) => {
  const {
    link, index, updateLink, removeLink,
  } = props;
  const checkSave = useSaveChecker();
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState({
    title: link.title,
    body: link.body,
    url: link.url,
  });

  useEffect(() => {
    if (link.title !== state.title || link.body !== state.body || link.url !== state.url) {
      setState({
        title: link.title,
        body: link.body,
        url: link.url,
      });
    }
  }, [link]);

  const handleUpdate = (field: string, value: string) => (
    setState({
      ...state,
      [field]: value,
    })
  );

  const handleSave = async () => updateLink(index, state.title, state.body, state.url);

  const handleDelete = async () => {
    removeLink(index);
    setShowModal(false);
  };

  return (
    <section className="field">
      <button type="button" onClick={() => setVisible(!visible)}>
        <h2 className="heading-secondary">{link.title}</h2>
        {
          visible
            ? <IconChevronUp fill={Colors.lightGray} className="icon small" />
            : <IconChevronDown fill={Colors.lightGray} className="icon small" />
        }
      </button>
      {
        visible
          ? (
            <>
              <label htmlFor="title" key="title">
                <span className="body light">Title</span>
                <input
                  value={state.title}
                  onChange={(event) => handleUpdate('title', event.currentTarget.value)}
                  name="title"
                  id="title"
                />
              </label>
              <label htmlFor="body" key="body">
                <span className="body light">Body</span>
                <textarea
                  value={state.body}
                  onChange={(event) => handleUpdate('body', event.currentTarget.value)}
                  name="body"
                  id="body"
                />
              </label>
              <label htmlFor="url" key="url">
                <span className="body light">URL</span>
                <input
                  value={state.url}
                  onChange={(event) => handleUpdate('url', event.currentTarget.value)}
                  name="url"
                  id="url"
                />
              </label>
              <Button labelText="Save" buttonType="submit" size="medium" onClick={async (...args) => checkSave(handleSave.bind(this, ...args))} />
              <Button className="mh10" color="red" labelText="Delete" buttonType="submit" size="medium" onClick={() => setShowModal(true)} />
            </>
          )
          : null
      }
      {showModal ? (
        <DialogModal
          title="Are you sure you want to delete this word?"
          visible={showModal}
          hideModal={() => setShowModal(false)}
          positiveAction={async (...args) => checkSave(handleDelete.bind(this, ...args))}
        />
      ) : null}
    </section>
  );
};

UsefulLink.propTypes = propTypes;

export default UsefulLink;
