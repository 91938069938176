import React, { useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import Dropdown from 'react-dropdown';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';

import { AppState } from '../state/reducers';
import { TTrendIndicator } from '../state/systemDocument/types';

import TrendIndicator from '../components/TrendIndicator';
import { selectors as systemDocumentSelectors } from '../state/systemDocument';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

const TrendIndicators = () => {
  const [selectedId, setSelectedId] = useState('');

  const trendIndicators = useSelector(
    (state: AppState) => systemDocumentSelectors.getTrendIndicators(state),
  );

  const dropdownOptions = map(trendIndicators, (item) => ({
    value: item.id,
    label: item.title['en-GB'],
  }));

  const types = uniqBy(
    map(trendIndicators, (item) => ({
      value: item.type,
      label: item.type,
    })),
    'value',
  );

  return (
    <>
      <section className="page">
        <DrawerButtons title="Trend indicators" showRightButton />
        <h2 className="heading-secondary">Select trend indicator</h2>
        <Dropdown
          options={dropdownOptions}
          onChange={(option) => setSelectedId(option.value)}
          value={selectedId}
          placeholder="Select trend indicator"
        />
        {
        !isEmpty(selectedId) && !isEmpty(get(trendIndicators, selectedId))
          ? (
            <TrendIndicator
              trendIndicator={get(trendIndicators, selectedId) as TTrendIndicator}
              types={types}
            />
          )
          : null
      }
      </section>
      <DrawerRight />
    </>
  );
};

export default TrendIndicators;
