import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import set from 'lodash/set';
import unionBy from 'lodash/unionBy';
import filter from 'lodash/filter';

import {
  TState,
  TActions,
  TUsefulLink,
  TWord,
} from './types';

import * as actionTypes from './actionTypes';

const initialState = {
  usefulLinks: {},
  glossary: {},
  treatmentTypes: {},
  trendIndicators: {},
  hospitals: {},
  profileQuestions: {},
  profileQuestionCategories: {},
  blogs: [],
};

const reducer = (state: TState = initialState, action: TActions): TState => {
  switch (action.type) {
    case actionTypes.ADD_SYSTEM_DOCUMENT: {
      return action.payload;
    }
    case actionTypes.ADD_USEFUL_LINKS: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      const links = get(state.usefulLinks, payload.language);

      if (isEmpty(links)) {
        return state;
      }

      const updatedLinks = union(links, payload.links);

      return {
        ...state,
        usefulLinks: {
          ...state.usefulLinks,
          [payload.language]: orderBy(updatedLinks, (link) => toLower(link.title), ['asc']),
        },
      };
    }
    case actionTypes.UPDATE_USEFUL_LINKS: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      const links = get(state.usefulLinks, payload.language);

      if (isEmpty(links)) {
        return state;
      }

      set(links as TUsefulLink[], payload.index, payload.link);

      return {
        ...state,
        usefulLinks: {
          ...state.usefulLinks,
          [payload.language]: orderBy(links, (link) => toLower(link.title), ['asc']),
        },
      };
    }
    case actionTypes.REMOVE_USEFUL_LINKS: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      const links = get(state.usefulLinks, payload.language);

      if (isEmpty(links)) {
        return state;
      }

      return {
        ...state,
        usefulLinks: {
          ...state.usefulLinks,
          [payload.language]: filter(links, (_, index) => index !== payload.index),
        },
      };
    }

    case actionTypes.ADD_GLOSSARY: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      const words = get(state.glossary, payload.language);

      if (isEmpty(words)) {
        return state;
      }

      const updatedWords = union(words, payload.words);

      return {
        ...state,
        glossary: {
          ...state.glossary,
          [payload.language]: orderBy(updatedWords, (word) => toLower(word.word), ['asc']),
        },
      };
    }
    case actionTypes.UPDATE_GLOSSARY: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      const words = get(state.glossary, payload.language);

      if (isEmpty(words)) {
        return state;
      }

      set(words as TWord[], payload.index, payload.word);

      return {
        ...state,
        glossary: {
          ...state.glossary,
          [payload.language]: orderBy(words, (word) => toLower(word.word), ['asc']),
        },
      };
    }
    case actionTypes.REMOVE_GLOSSARY: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      const words = get(state.glossary, payload.language);

      if (isEmpty(words)) {
        return state;
      }

      return {
        ...state,
        glossary: {
          ...state.glossary,
          [payload.language]: filter(words, (_, index) => index !== payload.index),
        },
      };
    }

    case actionTypes.UPDATE_TREATMENT_TYPES: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      return {
        ...state,
        treatmentTypes: {
          ...state.treatmentTypes,
          [payload.id]: payload,
        },
      };
    }
    case actionTypes.UPDATE_TREND_INDICATORS: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      return {
        ...state,
        trendIndicators: {
          ...state.trendIndicators,
          [payload.id]: payload,
        },
      };
    }
    case actionTypes.UPDATE_HOSPITALS: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      return {
        ...state,
        hospitals: {
          ...state.hospitals,
          [payload.id]: payload,
        },
      };
    }
    case actionTypes.UPDATE_PROFILE_QUESTIONS: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      return {
        ...state,
        profileQuestions: {
          ...state.profileQuestions,
          [payload.id]: payload,
        },
      };
    }
    case actionTypes.UPDATE_PROFILE_QUESTION_CATEGORIES: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      return {
        ...state,
        profileQuestionCategories: {
          ...state.profileQuestionCategories,
          [payload.id]: payload,
        },
      };
    }
    case actionTypes.UPDATE_DECISION_AID_QUESTIONS: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      return {
        ...state,
        decisionAidQuestions: {
          ...state.decisionAidQuestions,
          [payload.id]: payload,
        },
      };
    }
    case actionTypes.ADD_BLOGS:
    case actionTypes.UPDATE_BLOGS: {
      const { payload } = action;

      return {
        ...state,
        blogs: unionBy(state.blogs, payload, 'id'),
      };
    }
    case actionTypes.REMOVE_BLOG: {
      const { payload } = action;

      return {
        ...state,
        blogs: filter(state.blogs, (blog) => blog.id !== payload.id),
      };
    }

    default:
      return state;
  }
};

export default reducer;
