import { Reducer } from 'redux';

import * as actionTypes from './actionTypes';
import {
  UIState,
  UIActionTypes,
} from './types';

const initialState: UIState = {
  drawerLeftOpen: false,
  drawerRightOpen: false,
};

const reducer: Reducer<UIState, UIActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_DRAWER_LEFT: return {
      ...state,
      drawerLeftOpen: !state.drawerLeftOpen,
    };
    case actionTypes.TOGGLE_DRAWER_RIGHT: return {
      ...state,
      drawerRightOpen: !state.drawerRightOpen,
    };

    default: return state;
  }
};

export default reducer;
