/* eslint react/require-default-props: 0 */
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

import Colors from '../theme/Colors';
import '../css/Button.css';

const propTypes = {
  labelText: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
};

type Props = {
  labelText: ReactNode;
  onClick?: () => void;
  buttonType?: 'button' | 'submit';
  color?: string;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
};

const Button = (props: Props) => {
  const {
    labelText,
    onClick = noop,
    buttonType = 'button',
    color = Colors.secondary,
    size = 'small',
    disabled = false,
    className = '',
  } = props;

  const style = {
    backgroundColor: color,
  };

  if (buttonType === 'submit') {
    return (
      <button
        className={classNames('button contrast', size, className)}
        type="submit"
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        {labelText}
      </button>
    );
  }

  return (
    <button
      className={classNames('button contrast', size)}
      type="button"
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {labelText}
    </button>
  );
};

Button.propTypes = propTypes;

export default Button;
