import React from 'react';
import { NavLink } from 'react-router-dom';

import '../css/Navigation.css';
import Drawer from './Drawer';

const Navigation = () => (
  <Drawer>
    <section className="menu">
      <nav className="nav-menu">
        <section>
          <NavLink to="/" exact>Blogs</NavLink>
          <NavLink to="/decision-aid-questions" exact>Decision aid questions</NavLink>
          <NavLink to="/glossary">Glossary</NavLink>
          <NavLink to="/hospitals">Hospitals</NavLink>
          <NavLink to="/profile-questions">Profile questions</NavLink>
          <NavLink to="/profile-question-categories">Profile question categories</NavLink>
          <NavLink to="/treatment-types">Treatment types</NavLink>
          <NavLink to="/trend-indicators">Trend indicators</NavLink>
          <NavLink to="/useful-links">Useful links</NavLink>
        </section>
      </nav>
      <section className="upload-container">
        <NavLink to="/upload" className="upload-link">Upload data</NavLink>
      </section>
    </section>
  </Drawer>
);

export default Navigation;
