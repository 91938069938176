/* eslint-disable react/require-default-props */
import React from 'react';
import Modal from './Modal';

type Props = {
  visible: boolean;
  hideModal: () => void;
  title: string;
  positiveAction: () => void;
  positiveBtnTitle?: string;
  negativeBtnTitle?: string;
  description?: string;
};

const DialogModal = (props: Props) => {
  const {
    visible,
    hideModal,
    positiveBtnTitle = 'Ok',
    negativeBtnTitle = 'Cancel',
    positiveAction,
    title,
    description = '',
  } = props;

  return (
    <Modal
      visible={visible}
      hideModal={hideModal}
      actions={[
        {
          title: positiveBtnTitle,
          onClick: () => positiveAction(),
        },
        {
          title: negativeBtnTitle,
          onClick: hideModal,
          primary: true,
        },
      ]}
    >
      <section>
        <h1 className="heading">{title}</h1>
        <p className="heading">{description}</p>
      </section>
    </Modal>
  );
};

export default DialogModal;
