import React, { useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Dropdown from 'react-dropdown';
import toast from 'react-hot-toast';
import Button from '../components/Button';

import { actions as settingsActions, selectors as settingsSelectors, FileName } from '../state/settings';
import { actions as authActions } from '../state/auth';
import { AppState } from '../state/reducers';
import { useOctokit } from '../utils/hooks';

const dropdownOptions: { value: FileName, label: string }[] = [
  { value: 'breast', label: 'Breast' },
  { value: 'prostate', label: 'Prostate' },
];

const DocumentSelection = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const fileName = useSelector((state: AppState) => settingsSelectors.getFileName(state));

  const [isLoading, setIsLoading] = useState(false);

  const { downloadSystemDocumentAsync } = useOctokit();

  const handleLoginAsync = async (event: FormEvent) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      await toast.promise(
        downloadSystemDocumentAsync(),
        {
          loading: 'Loading...',
          success: () => {
            dispatch(authActions.set(true));
            history.push('/');
            return <b>Success</b>;
          },
          error: (error: any) => {
            if (process.env.NODE_ENV !== 'production') {
              // eslint-disable-next-line no-console
              console.log('[downloadSystemDocumentAsync] error =>', error);
            }
            return <b>Something went wrong, please try again later.</b>;
          },
        },
        {
          id: 'downloading',
        },
      );

      setIsLoading(false);
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('[downloadSystemDocumentAsync] download system document request failed');
      }
    }

    setIsLoading(false);
  };

  const onFileNameChange = (newFileName: FileName) => {
    dispatch(settingsActions.setFileName(newFileName));
  };

  return (

    <section>
      <form className="page login" onSubmit={handleLoginAsync}>
        <h2 className="heading-secondary">Select system document file</h2>
        <Dropdown
          options={dropdownOptions}
          onChange={(option) => onFileNameChange(option.value as FileName)}
          value={fileName}
          placeholder="Select system document file"
        />
        <Button
          labelText="Select"
          disabled={isLoading}
          buttonType="submit"
        />
      </form>
    </section>

  );
};

export default DocumentSelection;
