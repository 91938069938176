import { UIState } from './types';

const drawerLeftOpen = (state: UIState): boolean => state.drawerLeftOpen || false;

const drawerRightOpen = (state: UIState): boolean => state.drawerRightOpen || false;

export {
  drawerLeftOpen,
  drawerRightOpen,
};
