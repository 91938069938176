const ADD_SYSTEM_DOCUMENT = 'ADD_SYSTEM_DOCUMENT';
const ADD_USEFUL_LINKS = 'ADD_USEFUL_LINKS';
const UPDATE_USEFUL_LINKS = 'UPDATE_USEFUL_LINKS';
const REMOVE_USEFUL_LINKS = 'REMOVE_USEFUL_LINKS';
const ADD_GLOSSARY = 'ADD_GLOSSARY';
const UPDATE_GLOSSARY = 'UPDATE_GLOSSARY';
const REMOVE_GLOSSARY = 'REMOVE_GLOSSARY';
const UPDATE_TREATMENT_TYPES = 'UPDATE_TREATMENT_TYPES';
const UPDATE_TREND_INDICATORS = 'UPDATE_TREND_INDICATORS';
const UPDATE_HOSPITALS = 'UPDATE_HOSPITALS';
const UPDATE_PROFILE_QUESTION_CATEGORIES = 'UPDATE_PROFILE_QUESTION_CATEGORIES';
const UPDATE_PROFILE_QUESTIONS = 'UPDATE_PROFILE_QUESTIONS';
const UPDATE_DECISION_AID_QUESTIONS = 'UPDATE_DECISION_AID_QUESTIONS';
const ADD_BLOGS = 'ADD_BLOGS';
const UPDATE_BLOGS = 'UPDATE_BLOGS';
const REMOVE_BLOG = 'REMOVE_BLOG';

export {
  ADD_SYSTEM_DOCUMENT,
  ADD_USEFUL_LINKS,
  UPDATE_USEFUL_LINKS,
  REMOVE_USEFUL_LINKS,
  ADD_GLOSSARY,
  UPDATE_GLOSSARY,
  REMOVE_GLOSSARY,
  UPDATE_TREATMENT_TYPES,
  UPDATE_TREND_INDICATORS,
  UPDATE_HOSPITALS,
  UPDATE_PROFILE_QUESTION_CATEGORIES,
  UPDATE_PROFILE_QUESTIONS,
  UPDATE_DECISION_AID_QUESTIONS,
  ADD_BLOGS,
  UPDATE_BLOGS,
  REMOVE_BLOG,
};
