import React, { useState } from 'react';
import Dropdown from 'react-dropdown';

import { ReactComponent as IconChevronUp } from '../assets/icons/ChevronUp.svg';
import { ReactComponent as IconChevronDown } from '../assets/icons/ChevronDown.svg';
import Colors from '../theme/Colors';

type Option = {
  value: string;
  label: string;
};

type Props = {
  path: string;
  value: any;
  options: Option[];
  onChange: (path: string, value: any) => void;
};

const DropdownField = (props: Props) => {
  const {
    path,
    value,
    options,
    onChange,
  } = props;
  const [visible, setVisible] = useState(false);

  return (
    <section className="field">
      <button type="button" onClick={() => setVisible(!visible)}>
        <h2 className="heading-secondary">{path}</h2>
        {
          visible
            ? <IconChevronUp fill={Colors.lightGray} className="icon small" />
            : <IconChevronDown fill={Colors.lightGray} className="icon small" />
        }
      </button>
      {
        visible
          ? (
            <Dropdown
              options={options}
              onChange={(option) => onChange(path, option.value)}
              value={value}
              placeholder="Select an option"
            />
          )
          : null
      }
    </section>
  );
};

export default DropdownField;
