import reduce from 'lodash/reduce';

const generateLocalizedField = (languages: string[]) => (
  reduce(
    languages,
    (result, value) => ({
      ...result,
      [value]: '',
    }),
    {},
  )
);

export default generateLocalizedField;
