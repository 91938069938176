import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import union from 'lodash/union';
import map from 'lodash/map';
import compact from 'lodash/compact';

import Modal from './Modal';
import CheckboxGroup from './CheckboxGroup';
import config from '../config';

type Props = {
  visible: boolean;
  hideModal: () => void;
  onSave: (path: string, field: any) => void;
  path: string;
  initialTagsArray: string[];
  customValuesArray: string[];
};

const TagAddModalCheckbox = (props: Props) => {
  const {
    visible, hideModal, onSave, path, initialTagsArray, customValuesArray,
  } = props;

  const [values, setValues] = useState<string[]>([]);
  const [checkboxValues, setCheckboxValues] = useState<string[]>(initialTagsArray);
  const [customValues, setCustomValues] = useState(customValuesArray?.join(','));

  const options = map(config.TAGS, (tag) => ({
    value: tag.value,
    labelText: tag.label,
  }));

  const handleSave = () => {
    onSave(path, compact(union(values, customValues.split(','))));
  };

  const handleChange = (value: string, checked: boolean) => {
    if (checked) {
      return setCheckboxValues(without(checkboxValues, value));
    }

    return setCheckboxValues(union(checkboxValues, [value]));
  };

  const onChangeCustomValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomValues(event.currentTarget.value.trim());
  };

  useEffect(() => {
    setValues(union(checkboxValues, customValues));
  }, [checkboxValues, customValues]);

  return (
    <Modal
      visible={visible}
      hideModal={hideModal}
      actions={[
        {
          title: 'Cancel',
          onClick: hideModal,
        },
        {
          title: 'Save',
          onClick: handleSave,
          primary: true,
          disabled: isEmpty(values) && isEmpty(customValues),
        },
      ]}
    >
      <h1 className="heading">Select tag</h1>

      <CheckboxGroup
        values={checkboxValues}
        onChange={handleChange}
        options={options}
      />
      <section className="mt20">
        <h1 className="heading">Add tag</h1>
        <label htmlFor="title">
          <input
            value={customValues}
            onChange={onChangeCustomValues}
            placeholder="Add custom tags separated by comma"
            name="title"
            id="title"
          />
        </label>
      </section>
    </Modal>
  );
};

export default TagAddModalCheckbox;
