import React, { useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import Dropdown from 'react-dropdown';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { AppState } from '../state/reducers';
import { TProfileQuestionCategory } from '../state/systemDocument/types';

import ProfileQuestionCategory from '../components/ProfileQuestionCategory';
import { selectors as systemDocumentSelectors } from '../state/systemDocument';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

const ProfileQuestionCategories = () => {
  const [selectedId, setSelectedId] = useState('');

  const categories = useSelector(
    (state: AppState) => systemDocumentSelectors.getProfileQuestionCategories(state),
  );

  const dropdownOptions = map(categories, (item) => ({
    value: item.id,
    label: item.title['en-GB'],
  }));

  return (
    <>
      <section className="page">
        <DrawerButtons title="Profile question categories" showRightButton />
        <h2 className="heading-secondary">Select category</h2>
        <Dropdown
          options={dropdownOptions}
          onChange={(option) => setSelectedId(option.value)}
          value={selectedId}
          placeholder="Select category"
        />
        {
        !isEmpty(selectedId) && !isEmpty(get(categories, selectedId))
          ? (
            <ProfileQuestionCategory
              category={get(categories, selectedId) as TProfileQuestionCategory}
              key={selectedId}
            />
          )
          : null
      }
      </section>
      <DrawerRight />
    </>
  );
};

export default ProfileQuestionCategories;
