import * as actionTypes from './actionTypes';

import {
  TActions,
  TState,
  TAddUsefulLinksPayload,
  TUpdateUsefulLinksPayload,
  TAddGlossaryPayload,
  TUpdateGlossaryPayload,
  TUpdateTreatmentTypesPayload,
  TUpdateTrendIndicatorsPayload,
  TUpdateHospitalsPayload,
  TUpdateProfileQuestionsPayload,
  TUpdateProfileQuestionCategoriesPayload,
  TUpdateDecisionAidQuestionsPayload,
  TAddBlogsPayload,
  TUpdateBlogsPayload,
  TRemoveBlogPayload,
  TRemoveGlossaryPayload,
  TRemoveUsefulLinksPayload,
} from './types';

const addSystemDocument = (payload: TState): TActions => ({
  type: actionTypes.ADD_SYSTEM_DOCUMENT,
  payload,
});

const addUsefulLinks = (payload: TAddUsefulLinksPayload): TActions => ({
  type: actionTypes.ADD_USEFUL_LINKS,
  payload,
});

const updateUsefulLinks = (payload: TUpdateUsefulLinksPayload): TActions => ({
  type: actionTypes.UPDATE_USEFUL_LINKS,
  payload,
});

const removeUsefulLink = (payload: TRemoveUsefulLinksPayload): TActions => ({
  type: actionTypes.REMOVE_USEFUL_LINKS,
  payload,
});

const addGlossary = (payload: TAddGlossaryPayload): TActions => ({
  type: actionTypes.ADD_GLOSSARY,
  payload,
});

const updateGlossary = (payload: TUpdateGlossaryPayload): TActions => ({
  type: actionTypes.UPDATE_GLOSSARY,
  payload,
});

const removeGlossary = (payload: TRemoveGlossaryPayload): TActions => ({
  type: actionTypes.REMOVE_GLOSSARY,
  payload,
});

const updateTreatmentTypes = (payload: TUpdateTreatmentTypesPayload): TActions => ({
  type: actionTypes.UPDATE_TREATMENT_TYPES,
  payload,
});

const updateTrendIndicators = (payload: TUpdateTrendIndicatorsPayload): TActions => ({
  type: actionTypes.UPDATE_TREND_INDICATORS,
  payload,
});

const updateHospitals = (payload: TUpdateHospitalsPayload): TActions => ({
  type: actionTypes.UPDATE_HOSPITALS,
  payload,
});

const updateProfileQuestions = (payload: TUpdateProfileQuestionsPayload): TActions => ({
  type: actionTypes.UPDATE_PROFILE_QUESTIONS,
  payload,
});

const updateProfileQuestionCategories = (
  payload: TUpdateProfileQuestionCategoriesPayload,
): TActions => ({
  type: actionTypes.UPDATE_PROFILE_QUESTION_CATEGORIES,
  payload,
});

const updateDecisionAidQuestions = (
  payload: TUpdateDecisionAidQuestionsPayload,
): TActions => ({
  type: actionTypes.UPDATE_DECISION_AID_QUESTIONS,
  payload,
});

const addBlogs = (payload: TAddBlogsPayload): TActions => ({
  type: actionTypes.ADD_BLOGS,
  payload,
});

const updateBlogs = (payload: TUpdateBlogsPayload): TActions => ({
  type: actionTypes.UPDATE_BLOGS,
  payload,
});

const removeBlogById = (payload: TRemoveBlogPayload): TActions => ({
  type: actionTypes.REMOVE_BLOG,
  payload,
});

export {
  addSystemDocument,
  addUsefulLinks,
  updateUsefulLinks,
  removeUsefulLink,
  addGlossary,
  updateGlossary,
  removeGlossary,
  updateTreatmentTypes,
  updateTrendIndicators,
  updateHospitals,
  updateProfileQuestions,
  updateProfileQuestionCategories,
  updateDecisionAidQuestions,
  addBlogs,
  updateBlogs,
  removeBlogById,
};
