import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import isEqual from 'lodash/isEqual';
import { Dispatch } from 'redux';
import { SetStateAction } from 'react';
import { AppState } from '../state/reducers';
import { FileName, selectors as settingsSelectors } from '../state/settings';
import { actions as authActions } from '../state/auth';
import { actions as userActions } from '../state/user';
import octokitInit from '../services/octokitInit';
import {
  actions as systemDocumentActions,
  selectors as systemDocumentSelectors,
} from '../state/systemDocument';
import base64ToJSON from './base64ToJSON';
import JSONToBase64 from './JSONToBase64';

const documentPath: Record<FileName, string> = {
  prostate: 'prostate/prostate-ab854cda-e48c-4c4c-a120-eb4ab9b38168-0002-prod.json',
  breast: 'breast/breast-f4312562-6107-4d2c-938a-fcd4aefe1a8e-0002-prod.json',
};

const repoDefaultData = {
  owner: 'PxHealthCare',
  repo: 'system-documents',
};

const noChangesError = 'No changes were made';

export const useOctokit = () => {
  const dispatch = useDispatch();
  const fileName = useSelector((state: AppState) => settingsSelectors.getFileName(state));
  const storedSystemDocument = useSelector(
    (state: AppState) => systemDocumentSelectors.getAll(state),
  );

  const gitToken = sessionStorage.getItem('git_access_token');
  const octokit = octokitInit(gitToken as string);

  // eslint-disable-next-line no-console
  console.log('storedSystemDocument', storedSystemDocument);

  const deleteBranch = async (branchName: string) => {
    try {
      await octokit.rest.git.deleteRef({ // remove created branch
        ...repoDefaultData,
        ref: `heads/${branchName}`,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const uploadSystemDocumentAsync = async (
    ticketNumber: string,
    commit: string,
    comment: string,
  ) => {
    const baseBranchName = 'master';
    const newBranchName = `sde-${format(new Date(), "dd'-'MMM'-'HH'-'mm'-'ss")}`;

    const jiraTicketNumber = ticketNumber ? `[${ticketNumber}]` : '';

    try {
      const baseBranch = await octokit.rest.git.getRef({ // get base branch
        ...repoDefaultData,
        ref: `heads/${baseBranchName}`,
      });

      await octokit.rest.git.createRef({ // new branch
        ...repoDefaultData,
        ref: `refs/heads/${newBranchName}`,
        sha: baseBranch.data.object.sha,
      });

      const newBranchSystemDocument = await octokit.rest.repos.getContent({ // to get sha
        ...repoDefaultData,
        path: documentPath[fileName],
        ref: `refs/heads/${newBranchName}`,
      });

      if (!('content' in newBranchSystemDocument.data) || !('sha' in newBranchSystemDocument.data)) {
        return;
      }

      const newBranchSystemDocumentJSON = base64ToJSON(newBranchSystemDocument.data.content);

      if (isEqual(storedSystemDocument, newBranchSystemDocumentJSON)) {
        await deleteBranch(newBranchName);

        throw new Error(noChangesError);
      }

      const updatedFile = await octokit.rest.repos.createOrUpdateFileContents({
        ...repoDefaultData,
        path: documentPath[fileName],
        message: commit || 'update File',
        sha: newBranchSystemDocument.data.sha,
        content: JSONToBase64(storedSystemDocument),
        ref: `refs/heads/${newBranchName}`, // default branch name
        branch: newBranchName,
      });

      if (updatedFile.data.commit.sha) {
        await octokit.git.updateRef({
          ...repoDefaultData,
          ref: `heads/${newBranchName}`,
          sha: updatedFile.data.commit.sha,
        });

        await octokit.pulls.create({
          ...repoDefaultData,
          head: newBranchName,
          base: baseBranchName,
          title: `${jiraTicketNumber} ${fileName} ${newBranchName}`,
          body: comment || '',
        });
      }
    } catch (error: any) {
      if (error.message !== noChangesError) { // if branch wasn't deleted
        await deleteBranch(newBranchName);
      }

      throw error;
    }
  };

  const downloadSystemDocumentAsync = async () => {
    try {
      const result = await octokit.rest.repos.getContent({
        ...repoDefaultData,
        path: `${documentPath[fileName]}`,
      });
      if ('content' in result.data) {
        const systemDocumentJSON = base64ToJSON(result.data.content);
        dispatch(systemDocumentActions.addSystemDocument(systemDocumentJSON));
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return { downloadSystemDocumentAsync, uploadSystemDocumentAsync };
};

const tryDecorator = async (childeFunction: () => void) => {
  try {
    childeFunction();
  } catch (error: any) {
    throw new Error(error);
  }
};

export const useSaveChecker = () => {
  const dispatch = useDispatch();
  const checkSave = async (checkedFunction: (...args: any) => Promise<void>) => {
    try {
      await toast.promise(
        tryDecorator(() => checkedFunction()),
        {
          loading: 'Loading...',
          success: () => {
            dispatch(authActions.set(true));
            return 'Success';
          },
          error: () => 'Something went wrong, please try again later.',
        },
        {
          id: 'saving',
        },
      );
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('[checkSave] saving changes to redux was failed');
      }
    }
  };

  return checkSave;
};

export const useLogOut = (dispatch: Dispatch<SetStateAction<any>>) => {
  const logOut = async () => {
    try {
      dispatch(authActions.logout());
      await fetch(`https://${process.env.REACT_APP_COGNITO_DOMAIN_NAME}/logout?client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}`, {
        method: 'GET',
        mode: 'no-cors',
      });
      dispatch(userActions.userCognito(''));
      dispatch(userActions.userGitHub(''));
      sessionStorage.clear();
      dispatch(authActions.set(false));

      window.location.href = `https://${process.env.REACT_APP_COGNITO_DOMAIN_NAME}/login?response_type=code&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COGNITO_CALLBACK_URL}`;
    } catch (error: any) {
      toast.error('Sorry, failed to log out. Try again later please.');
      throw new Error(error);
    }
  };

  return logOut;
};
