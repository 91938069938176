/* eslint-disable max-len */
import React, { useState } from 'react';
import map from 'lodash/map';
import keys from 'lodash/keys';
import get from 'lodash/get';
import Dropdown from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../state/reducers';

import Word from '../components/Word';
import GlossaryAddModal from '../components/GlossaryAddModal';
import { actions as systemDocumentActions, selectors as systemDocumentSelectors } from '../state/systemDocument';
import { ReactComponent as IconAdd } from '../assets/icons/Add.svg';
import Colors from '../theme/Colors';
import { useSaveChecker } from '../utils/hooks';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

const Glossary = () => {
  const [language, setLanguage] = useState('');
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const checkSave = useSaveChecker();

  const glossary = useSelector((state: AppState) => systemDocumentSelectors.getGlossary(state));

  const languageOptions = map(keys(glossary), (key) => ({
    value: key,
    label: key,
  }));

  const updateWord = async (index: number, word: string, description: string) => {
    dispatch(systemDocumentActions.updateGlossary({
      word: { word, description },
      language,
      index,
    }));
  };

  const removeWord = async (index: number) => {
    dispatch(systemDocumentActions.removeGlossary({ index, language }));
  };

  return (
    <>
      <section className="page">
        <DrawerButtons title="Glossary" showRightButton />
        <button type="button" className="button contrast add-field" onClick={() => setVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add word
        </button>
        <h2 className="heading-secondary">Select language</h2>
        <Dropdown
          options={languageOptions}
          onChange={(option) => setLanguage(option.value)}
          value={language}
          placeholder="Select language"
        />
        <section>
          {map(get(glossary, language), (word, index) => (
            <Word
              key={index}
              word={word}
              index={index as unknown as number}
              updateWord={async (...args) => checkSave(updateWord.bind(this, ...args))}
              removeWord={async (...args) => checkSave(removeWord.bind(this, ...args))}
            />
          ))}
        </section>
      </section>
      <DrawerRight />
      <GlossaryAddModal
        visible={visible}
        hideModal={() => setVisible(false)}
        languageOptions={languageOptions}
      />
    </>
  );
};

export default Glossary;
