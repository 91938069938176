import { FileName, FileNameSetActionType } from './types';
import * as actionTypes from './actionTypes';

const setFileName = (payload: FileName): FileNameSetActionType => ({
  type: actionTypes.SET_FILE_NAME,
  payload,
});

export {
  // eslint-disable-next-line import/prefer-default-export
  setFileName,
};
