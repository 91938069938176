import { all } from 'redux-saga/effects';

const DEV = process.env.NODE_ENV !== 'production';

export default function* rootSaga() {
  try {
    yield all([]);
  } catch (error) {
    if (DEV) {
      console.log('[rootSaga]', 'catch', error); // eslint-disable-line no-console
    }
  }
}
