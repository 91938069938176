import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { THospital } from '../state/systemDocument/types';

import { ReactComponent as IconChevronUp } from '../assets/icons/ChevronUp.svg';
import { ReactComponent as IconChevronDown } from '../assets/icons/ChevronDown.svg';
import Button from './Button';
import Colors from '../theme/Colors';
import { actions as systemDocumentActions } from '../state/systemDocument';
import { useSaveChecker } from '../utils/hooks';

type Props = {
  hospital: THospital;
};

const Hospital = (props: Props) => {
  const { hospital } = props;

  const dispatch = useDispatch();
  const checkSave = useSaveChecker();

  const [visible, setVisible] = useState(false);
  const [state, setState] = useState(hospital);

  useEffect(() => {
    if (!isEqual(hospital, state)) {
      setState(hospital);
    }
  }, [hospital]);

  const handleUpdate = (field: string, value: string) => (
    setState({
      ...state,
      [field]: value,
    })
  );

  const handleSave = async () => { dispatch(systemDocumentActions.updateHospitals(state)); };

  return (
    <section className="field">
      <button type="button" onClick={() => setVisible(!visible)}>
        <h2 className="heading-secondary">{hospital.name}</h2>
        {
          visible
            ? <IconChevronUp fill={Colors.lightGray} className="icon small" />
            : <IconChevronDown fill={Colors.lightGray} className="icon small" />
        }
      </button>
      {
        visible
          ? (
            <>
              <label htmlFor="name" key="name">
                <span className="body light">Name</span>
                <input
                  value={state.name}
                  onChange={(event) => handleUpdate('name', event.currentTarget.value)}
                  name="name"
                  id="name"
                />
              </label>
              <label htmlFor="hospitalGroup" key="hospitalGroup">
                <span className="body light">Hospital group</span>
                <input
                  value={state.hospitalGroup}
                  onChange={(event) => handleUpdate('hospitalGroup', event.currentTarget.value)}
                  name="hospitalGroup"
                  id="hospitalGroup"
                />
              </label>
              <label htmlFor="publicKey" key="publicKey">
                <span className="body light">Public Key</span>
                <input
                  value={state.publicKey}
                  onChange={(event) => handleUpdate('publicKey', event.currentTarget.value)}
                  name="publicKey"
                  id="publicKey"
                />
              </label>
              <Button labelText="Save" buttonType="submit" size="medium" onClick={async (...args) => checkSave(handleSave.bind(this, ...args))} />
            </>
          )
          : null
      }
    </section>
  );
};

export default Hospital;
