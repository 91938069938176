/* eslint react/require-default-props: 0 */
import React, { useState } from 'react';
import map from 'lodash/map';
import keys from 'lodash/keys';
import first from 'lodash/first';
import get from 'lodash/get';
import Dropdown from 'react-dropdown';

import { ReactComponent as IconChevronUp } from '../assets/icons/ChevronUp.svg';
import { ReactComponent as IconChevronDown } from '../assets/icons/ChevronDown.svg';
import Colors from '../theme/Colors';

type Props = {
  field: {
    [locale: string]: string;
  };
  name: string;
  onChange: (path: string, value: any) => void;
  textarea?: boolean;
};

const LocalizedField = (props: Props) => {
  const {
    field,
    name,
    onChange,
    textarea = false,
  } = props;
  const locales = keys(field);

  const [locale, setLocale] = useState<string>(first(locales) as string);
  const [visible, setVisible] = useState(false);

  const options = map(locales, (l) => ({
    value: l,
    label: l,
  }));

  const path = `${name}.${locale}`;
  const value = get(field, locale);

  const TextInput = (
    <input
      value={value}
      onChange={(event) => onChange(path, event.currentTarget.value)}
      name={path}
      id={path}
    />
  );

  const TextArea = (
    <textarea
      value={value}
      onChange={(event) => onChange(path, event.currentTarget.value)}
      name={path}
      id={path}
    />
  );

  return (
    <section className="field">
      <button type="button" onClick={() => setVisible(!visible)}>
        <h2 className="heading-secondary">{name}</h2>
        {
          visible
            ? <IconChevronUp fill={Colors.lightGray} className="icon small" />
            : <IconChevronDown fill={Colors.lightGray} className="icon small" />
        }
      </button>
      {
        visible
          ? (
            <section className="row">
              <Dropdown
                options={options}
                onChange={(option) => setLocale(option.value)}
                value={locale}
                placeholder="Select language"
              />
              <label htmlFor={path} key={path}>
                {textarea ? TextArea : TextInput}
              </label>
            </section>
          )
          : null
      }
    </section>
  );
};

export default LocalizedField;
