import { Reducer } from 'redux';

import * as actionTypes from './actionTypes';
import { SettingsActionTypes, SettingState } from './types';

const initialState: SettingState = {
  fileName: 'breast',
};

const reducer: Reducer<SettingState, SettingsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILE_NAME: {
      const { payload } = action;

      return { ...state, fileName: payload };
    }
    default: return state;
  }
};

export default reducer;
