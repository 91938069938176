import React, { useState } from 'react';
import map from 'lodash/map';
import keys from 'lodash/keys';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Dropdown from 'react-dropdown';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../state/reducers';

import UsefulLink from '../components/UsefulLink';
import UsefulLinkAddModal from '../components/UsefulLinkAddModal';
import { actions as systemDocumentActions, selectors as systemDocumentSelectors } from '../state/systemDocument';
import { ReactComponent as IconAdd } from '../assets/icons/Add.svg';
import Colors from '../theme/Colors';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

const UsefulLinks = () => {
  const [language, setLanguage] = useState('');
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const usefulLinks = useSelector(
    (rstate: AppState) => systemDocumentSelectors.getUsefulLinks(rstate),
  );

  const languageOptions = map(keys(usefulLinks), (key) => ({
    value: key,
    label: key,
  }));

  const updateLink = async (index: number, title: string, body: string, url: string) => {
    const links = get(usefulLinks, language);

    if (isEmpty(links)) {
      return false;
    }

    const link = get(links, index);

    const updatedLink = {
      ...link,
      title,
      body,
      url,
    };

    dispatch(systemDocumentActions.updateUsefulLinks({ link: updatedLink, language, index }));

    return true;
  };

  const removeLink = async (index: number) => (
    dispatch(systemDocumentActions.removeUsefulLink({ index, language }))
  );

  return (
    <>
      <section className="page">
        <DrawerButtons title="Useful links" showRightButton />
        <button type="button" className="button contrast add-field" onClick={() => setVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add useful link
        </button>
        <h2 className="heading-secondary">Select language</h2>
        <Dropdown
          options={languageOptions}
          onChange={(option) => setLanguage(option.value)}
          value={language}
          placeholder="Select language"
        />
        <section>
          {map(get(usefulLinks, language), (link, index) => (
            <UsefulLink
              key={index}
              link={link}
              index={index as unknown as number}
              updateLink={updateLink}
              removeLink={removeLink}
            />
          ))}
        </section>
      </section>
      <DrawerRight />
      <UsefulLinkAddModal
        visible={visible}
        hideModal={() => setVisible(false)}
        languageOptions={languageOptions}
      />
    </>
  );
};

export default UsefulLinks;
