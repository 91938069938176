import React, { useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import Dropdown from 'react-dropdown';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { AppState } from '../state/reducers';
import { TTreatmentType } from '../state/systemDocument/types';

import TreatmentType from '../components/TreatmentType';
import { selectors as systemDocumentSelectors } from '../state/systemDocument';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

const TreatmentTypes = () => {
  const [selectedId, setSelectedId] = useState('');

  const treatmentTypes = useSelector(
    (state: AppState) => systemDocumentSelectors.getTreatmentTypes(state),
  );

  const dropdownOptions = map(treatmentTypes, (item) => ({
    value: item.id,
    label: item.title['en-GB'],
  }));

  return (
    <>
      <section className="page">
        <DrawerButtons title="Treatment types" showRightButton />
        <h2 className="heading-secondary">Select treatment type</h2>
        <Dropdown
          options={dropdownOptions}
          onChange={(option) => setSelectedId(option.value)}
          value={selectedId}
          placeholder="Select treatment type"
        />
        {
        !isEmpty(selectedId) && !isEmpty(get(treatmentTypes, selectedId))
          ? (
            <TreatmentType
              treatmentType={get(treatmentTypes, selectedId) as TTreatmentType}
              key={selectedId}
            />
          )
          : null
      }
      </section>
      <DrawerRight />
    </>
  );
};

export default TreatmentTypes;
