import React, { useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import assignIn from 'lodash/assignIn';
import union from 'lodash/union';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../state/reducers';

import { TProfileQuestionCategory } from '../state/systemDocument/types';
import LocalizedField from './LocalizedField';
import FieldAddModal from './FieldAddModal';
import ProfileQuestionAddModal from './ProfileQuestionAddModal';
import { ReactComponent as IconAdd } from '../assets/icons/Add.svg';
import { actions as systemDocumentActions, selectors as systemDocumentSelectors } from '../state/systemDocument';

import Colors from '../theme/Colors';
import { useSaveChecker } from '../utils/hooks';

type Props = {
  category: TProfileQuestionCategory;
};

const ProfileQuestionCategory = (props: Props) => {
  const { category } = props;

  const dispatch = useDispatch();
  const checkSave = useSaveChecker();

  const [visible, setVisible] = useState(false);
  const [questionsAddVisible, setQuestionsAddVisible] = useState(false);

  const profileQuestions = useSelector(
    (state: AppState) => systemDocumentSelectors.getProfileQuestionsByIds(
      state,
      category.profileQuestionIds,
    ),
  );

  const updateField = (path: string, value: any) => {
    const updatedObject = set(category, path, value);

    dispatch(systemDocumentActions.updateProfileQuestionCategories(updatedObject));
  };

  const addField = async (field: any) => {
    dispatch(systemDocumentActions.updateProfileQuestionCategories(assignIn(category, field)));

    setVisible(false);
  };

  const addProfileQuestions = async (values: string[]) => {
    const profileQuestionIds = union(category.profileQuestionIds, values);

    dispatch(systemDocumentActions.updateProfileQuestionCategories(set(category, 'profileQuestionIds', profileQuestionIds)));

    setQuestionsAddVisible(false);
  };

  return (
    <>
      <section className="trend-indicator">
        <h1 className="heading">
          {get(category, 'title["en-GB"]')}
          {' '}
          |
          {' '}
          {category.id}
        </h1>
        <button type="button" className="button contrast add-field" onClick={() => setVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add field
        </button>
        <LocalizedField
          name="title"
          field={category.title}
          onChange={updateField}
        />
        <LocalizedField
          name="description"
          field={category.description}
          onChange={updateField}
          textarea
        />
        <h2 className="heading-secondary">Questions</h2>
        {map(profileQuestions, (question) => (
          <p key={question.id} className="body light">{get(question, 'title["en-GB"]')}</p>
        ))}
        <button type="button" className="button contrast add-field" onClick={() => setQuestionsAddVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add questions
        </button>
      </section>
      <FieldAddModal
        visible={visible}
        hideModal={() => setVisible(false)}
        onSave={async (...args) => checkSave(addField.bind(this, ...args))}
      />
      <ProfileQuestionAddModal
        visible={questionsAddVisible}
        hideModal={() => setQuestionsAddVisible(false)}
        onSave={async (...args) => checkSave(addProfileQuestions.bind(this, ...args))}
        profileQuestionIds={category.profileQuestionIds}
      />
    </>
  );
};

export default ProfileQuestionCategory;
