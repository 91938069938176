import React, { useState } from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import Dropdown from 'react-dropdown';
import isEmpty from 'lodash/isEmpty';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { AppState } from '../state/reducers';
import { TBlog } from '../state/systemDocument/types';

import Blog from '../components/Blog';
import BlogAddModal from '../components/BlogAddModal';
import {
  selectors as systemDocumentSelectors,
  actions as systemDocumentActions,
} from '../state/systemDocument';
import { ReactComponent as IconAdd } from '../assets/icons/Add.svg';
import Colors from '../theme/Colors';
import { useSaveChecker } from '../utils/hooks';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

const Blogs = () => {
  const [selectedId, setSelectedId] = useState('');
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const checkSave = useSaveChecker();

  const blogs = useSelector(
    (state: AppState) => systemDocumentSelectors.getBlogs(state),
  );

  const dropdownOptions = map(blogs, (item) => ({
    value: item.id,
    label: item.title['en-GB'],
  }));

  const deleteBlog = async () => {
    dispatch(systemDocumentActions.removeBlogById({ id: selectedId }));
    setSelectedId('');
  };

  return (
    <>
      <section className="page">
        <DrawerButtons title="Blogs" showRightButton />
        <button type="button" className="button contrast add-field" onClick={() => setVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add blog
        </button>
        <h2 className="heading-secondary">Select blog</h2>
        <Dropdown
          options={dropdownOptions}
          onChange={(option) => setSelectedId(option.value)}
          value={selectedId}
          placeholder="Select blog"
        />
        {
          !isEmpty(selectedId) && !isEmpty(find(blogs, { id: selectedId }))
            ? (
              <Blog
                blog={find(blogs, { id: selectedId }) as TBlog}
                key={selectedId}
                deleteBlog={async (...args) => checkSave(deleteBlog.bind(this, ...args))}
                closeBlog={setSelectedId}
              />
            )
            : null
        }
      </section>
      <DrawerRight />
      <BlogAddModal
        visible={visible}
        hideModal={() => setVisible(false)}
      />
    </>
  );
};

export default Blogs;
