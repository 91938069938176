import { Reducer } from 'redux';

import * as actionTypes from './actionTypes';
import {
  AuthState, AuthActionTypes, AuthUserActionType,
} from './types';

const initialState: AuthState = {
  userCognito: '',
  userGitHub: '',
};

const reducer: Reducer<AuthState, AuthActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_COGNITO: {
      const { payload } = action as AuthUserActionType;

      return { ...state, userCognito: payload };
    }
    case actionTypes.USER_GITHUB: {
      const { payload } = action as AuthUserActionType;

      return { ...state, userGitHub: payload };
    }
    default: return state;
  }
};

export default reducer;
