import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import EventListener from 'react-event-listener';

import { AppState } from '../state/reducers';
import { actions as uiActions, selectors as uiSelectors } from '../state/ui';
import '../css/Navigation.css';
import '../css/Drawer.css';

const propTypes = {
  // eslint-disable-next-line react/require-default-props
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
};

type Props = {
  position?: 'left' | 'right';
  children: ReactNode;
};

const Drawer = (props: Props) => {
  const { position = 'left', children } = props;

  const drawerLeftOpen = useSelector((state: AppState) => uiSelectors.drawerLeftOpen(state.ui));
  const drawerRightOpen = useSelector((state: AppState) => uiSelectors.drawerRightOpen(state.ui));

  const dispatch = useDispatch();

  const open = position === 'left' ? drawerLeftOpen : drawerRightOpen;

  const toggleDrawer = () => {
    if (position === 'left') {
      return dispatch(uiActions.toggleDrawerLeft());
    }

    return dispatch(uiActions.toggleDrawerRight());
  };

  const closeDrawer = () => {
    if (open) {
      return toggleDrawer();
    }

    return false;
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      toggleDrawer();
    }
  };

  return (
    <section className={classNames('drawer', position, { open })}>
      <section className="overlay" onClick={toggleDrawer} role="presentation" />
      <section // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        className="drawer-content"
        onClick={closeDrawer}
      >
        {children}
      </section>
      {
        open
          ? <EventListener target="window" onKeyUp={handleKeyUp} />
          : null
      }
    </section>
  );
};

Drawer.propTypes = propTypes;

export default Drawer;
