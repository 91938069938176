import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Dropdown from 'react-dropdown';

import Modal from './Modal';
import { actions as systemDocumentActions } from '../state/systemDocument';
import { useSaveChecker } from '../utils/hooks';

type LanguageOption = {
  value: string;
  label: string;
};

type Props = {
  visible: boolean;
  hideModal: () => void;
  languageOptions: LanguageOption[];
};

const propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  languageOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

const GlossaryAddModal = (props: Props) => {
  const { visible, hideModal, languageOptions } = props;
  const dispatch = useDispatch();
  const checkSave = useSaveChecker();
  const [state, setState] = useState({
    word: '',
    description: '',
    language: '',
  });

  const handleUpdate = (field: string, value: string) => (
    setState({
      ...state,
      [field]: value,
    })
  );

  const resetState = () => setState({
    word: '',
    description: '',
    language: '',
  });

  const handleSave = async () => {
    dispatch(systemDocumentActions.addGlossary({
      words: [{
        word: state.word,
        description: state.description,
      }],
      language: state.language,
    }));
    resetState();
    hideModal();
  };

  const disabled = !state.word || !state.description || !state.language;

  return (
    <Modal
      visible={visible}
      hideModal={hideModal}
      actions={[
        {
          title: 'Cancel',
          onClick: () => {
            resetState();
            hideModal();
          },
        },
        {
          title: 'Save',
          onClick: async (...args) => checkSave(handleSave.bind(this, ...args)),
          primary: true,
          disabled,
        },
      ]}
    >
      <section>
        <h1 className="heading">Add word to glossary</h1>
        <h2 className="body light">Select language</h2>
        <Dropdown
          options={languageOptions}
          onChange={(option) => handleUpdate('language', option.value)}
          value={state.language}
          placeholder="Select language"
        />
        <label htmlFor="word" key="word">
          <span className="body light">Word</span>
          <input
            value={state.word}
            onChange={(event) => handleUpdate('word', event.currentTarget.value)}
            name="word"
            id="word"
          />
        </label>
        <label htmlFor="description" key="description">
          <span className="body light">Description</span>
          <textarea
            value={state.description}
            onChange={(event) => handleUpdate('description', event.currentTarget.value)}
            name="description"
            id="description"
          />
        </label>
      </section>
    </Modal>
  );
};

GlossaryAddModal.propTypes = propTypes;

export default GlossaryAddModal;
