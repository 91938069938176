const config = {
  api: {
    url: 'https://wcoedt1tc5.execute-api.eu-central-1.amazonaws.com/prod',
    timeout: 20000,
  },
  TAGS: [
    {
      value: 'country:uk',
      label: 'country:uk',
    },
    {
      value: 'country:us',
      label: 'country:us',
    },
    {
      value: 'country:nl',
      label: 'country:nl',
    },
    {
      value: 'locale:en-GB',
      label: 'locale:en-GB',
    },
    {
      value: 'locale:en-US',
      label: 'locale:en-US',
    },
    {
      value: 'locale:es-US',
      label: 'locale:es-US',
    },
    {
      value: 'drugModule:enhertu',
      label: 'drugModule:enhertu',
    },
    {
      value: 'drugModule:lynparza',
      label: 'drugModule:lynparza',
    },
  ],
};

export default config;
