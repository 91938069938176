import React, { useEffect } from 'react';

const GITHUB_CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID;
const GITHUB_AUTHORIZE_URL = process.env.REACT_APP_GITHUB_AUTHORIZE_URL;

const GitHubAuth = () => {
  const isGitHubAuth = sessionStorage.getItem('is_github_auth') === 'true';

  useEffect(() => {
    if (!isGitHubAuth) {
      const params = new URLSearchParams({
        client_id: GITHUB_CLIENT_ID!,
        state: 'random_state_string', // Prevent CSRF attacks
        scope: 'repo,read:user',
      });

      window.location.href = `${GITHUB_AUTHORIZE_URL}?${params.toString()}`;
    }
  }, [isGitHubAuth]);

  return (
    <div>
      {' '}
      Loading...
    </div>
  );
};

export default GitHubAuth;
