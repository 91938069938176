import React, { useState } from 'react';

import Modal from './Modal';

type Props = {
  visible: boolean;
  hideModal: () => void;
  onSave: (field: any) => void;
};

const FieldAddModal = (props: Props) => {
  const { visible, hideModal, onSave } = props;

  const [title, setTitle] = useState('');

  const handleSave = () => {
    const field = {
      [title]: '',
    };

    onSave(field);
  };

  return (
    <Modal
      visible={visible}
      hideModal={hideModal}
      actions={[
        {
          title: 'Cancel',
          onClick: hideModal,
        },
        {
          title: 'Save',
          onClick: handleSave,
          primary: true,
          disabled: !title,
        },
      ]}
    >
      <h1 className="heading">Add field</h1>
      <label htmlFor="title">
        <span className="body light">Title</span>
        <input
          value={title}
          onChange={(event) => setTitle(event.currentTarget.value)}
          name="title"
          id="title"
        />
      </label>
    </Modal>
  );
};

export default FieldAddModal;
