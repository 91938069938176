/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Octokit } from '@octokit/rest';
import { actions as userActions } from '../state/user';

const GitHubAuth = () => {
  const dispatch = useDispatch();

  // Once redirected back from GitHub, extract the authorization code from the URL
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    if (code) {
      // Call the AWS API Gateway endpoint instead of local server
      // TODO: Remove test  deployment URL when fixed
      fetch('https://mltypmihsj.execute-api.us-east-1.amazonaws.com/prod/auth/github', {
        // fetch('https://app.sde.owi.se/auth/github', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      })
        .then((res) => res.json())
        .then((data) => {
          const gitAccessToken = data.message;
          if (gitAccessToken) {
            sessionStorage.setItem('is_github_auth', 'true');
            sessionStorage.setItem('git_access_token', gitAccessToken);

            // Use Octokit to fetch user data from GitHub
            const octokit = new Octokit({
              auth: gitAccessToken,
            });
            const getGitHubUser = async () => {
              const { data: userData } = await octokit.request('GET /user');
              dispatch(userActions.userGitHub(userData.login));
            };
            getGitHubUser();
          }
        })
        .catch((error) => {
          console.error('[requestToLambdaAPI] error:', error);
        });
    }
  }, []);

  return (
    <div>
      {' '}
      Loading...
    </div>
  );
};

export default GitHubAuth;

// /* eslint-disable max-len */
// import React from 'react';
// import { useDispatch } from 'react-redux';
// import { Octokit } from '@octokit/rest';
// import Button from 'src/components/Button';
// import { actions as userActions } from '../state/user';

// const GetGitHubToken = () => {
//   const dispatch = useDispatch();

//   // Once redirected back from GitHub, extract the authorization code from the URL
//   const requestToLambdaAPI = () => {
//     const query = new URLSearchParams(window.location.search);
//     const code = query.get('code');
//     console.log('code =>', code);
//     if (code) {
//       // Call the AWS API Gateway endpoint instead of local server
//       // TODO: Remove test  deployment URL when fixed
//       fetch('https://mltypmihsj.execute-api.us-east-1.amazonaws.com/prod/auth/github', {
//         //   fetch('https://app.sde.owi.se/auth/github', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ code }),
//       })
//         .then((res) => res.json())
//         .then((data) => {
//           const gitAccessToken = data.message;
//           if (gitAccessToken) {
//             sessionStorage.setItem('is_github_auth', 'true');
//             sessionStorage.setItem('git_access_token', gitAccessToken);

//             // Use Octokit to fetch user data from GitHub
//             const octokit = new Octokit({
//               auth: gitAccessToken,
//             });
//             const getGitHubUser = async () => {
//               const { data: userData } = await octokit.request('GET /user');
//               dispatch(userActions.userGitHub(userData.login));
//             };
//             getGitHubUser();
//           }
//         })
//         .catch((error) => {
//           console.error('[requestToLambdaAPI] error:', error);
//         });
//     }
//   };

//   return (
//     <div>
//       <h3>Send auth code to AWS Lambda to get access_token back</h3>
//       <Button labelText="request To Lambda API" onClick={requestToLambdaAPI} />
//     </div>
//   );
// };

// export default GetGitHubToken;
