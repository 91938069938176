import React from 'react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';

import { AppState } from '../state/reducers';

import Hospital from '../components/Hospital';
import { selectors as systemDocumentSelectors } from '../state/systemDocument';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

const Hospitals = () => {
  const hospitals = useSelector((state: AppState) => systemDocumentSelectors.getHospitals(state));

  return (
    <>
      <section className="page">
        <DrawerButtons title="Hospitals" showRightButton />
        <section>
          {map(hospitals, (hospital) => (
            <Hospital key={hospital.id} hospital={hospital} />
          ))}
        </section>
      </section>
      <DrawerRight />
    </>
  );
};

export default Hospitals;
