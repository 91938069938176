import toast from 'react-hot-toast';
import { actions as userActions } from '../state/user';

const getUserInfo = async (dispatch: any, token:string) => {
  try {
    const tokenResponse = await fetch(`https://${process.env.REACT_APP_COGNITO_DOMAIN_NAME}/oauth2/userInfo`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-amz-json-1.1',
        Authorization: `Bearer ${token}`,
      },
    });

    const tokenData = await tokenResponse.json();
    dispatch(userActions.userCognito(tokenData.email));
  } catch (error: any) {
    toast.error('Sorry, failed to fetch tokens. Try again later please.');
    throw new Error(error);
  }
};
export default getUserInfo;
