/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  ReactNode,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { actions as authActions } from '../state/auth';
import { actions as userActions } from '../state/user';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const isCognitoLoggedIn = sessionStorage.getItem('is_cognito_logged_in');
  const isGitLoggedIn = sessionStorage.getItem('is_github_auth');
  const expiredIn = sessionStorage.getItem('expired_in');
  const now = new Date(Date.now()).toUTCString();

  useEffect(() => {
    if (isCognitoLoggedIn === null
     || isGitLoggedIn === null
        || (expiredIn !== null && Date.parse(expiredIn) < Date.parse(now))) {
      dispatch(userActions.userCognito(''));
      dispatch(userActions.userGitHub(''));
      sessionStorage.clear();
      dispatch(authActions.set(false));
      window.location.href = `https://${process.env.REACT_APP_COGNITO_DOMAIN_NAME}/login?response_type=code&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COGNITO_CALLBACK_URL}`;
    }
  });

  return (
    <>
      { children }
    </>
  );
};
export default ProtectedRoute;
