import React, { useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import assignIn from 'lodash/assignIn';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';

import { TDecisionAidQuestion } from '../state/systemDocument/types';
import LocalizedField from './LocalizedField';
import FieldAddModal from './FieldAddModal';
import { ReactComponent as IconAdd } from '../assets/icons/Add.svg';
import { actions as systemDocumentActions } from '../state/systemDocument';

import Colors from '../theme/Colors';

type Props = {
  question: TDecisionAidQuestion;
};

const DecisionAidQuestion = (props: Props) => {
  const { question } = props;

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  const updateField = (path: string, value: any) => {
    const updatedObject = set(question, path, value);

    dispatch(systemDocumentActions.updateDecisionAidQuestions(updatedObject));
  };

  const addField = (field: any) => {
    dispatch(systemDocumentActions.updateDecisionAidQuestions(assignIn(question, field)));

    setVisible(false);
  };

  return (
    <>
      <section className="trend-indicator">
        <h1 className="heading">
          {get(question, 'title["en-GB"]')}
          {' '}
          |
          {' '}
          {question.id}
        </h1>
        <button type="button" className="button contrast add-field" onClick={() => setVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add field
        </button>
        <LocalizedField
          name="title"
          field={question.title}
          onChange={updateField}
        />
        <LocalizedField
          name="description"
          field={question.description}
          onChange={updateField}
          textarea
        />
        {
          !isEmpty(question.answers)
            ? (
              <>
                <h1 className="heading">Answer options</h1>
                {map(question.answers, (answer, index) => (
                  <div key={`answers.${index}.title`} className="option">
                    <h2 className="heading-secondary">
                      {get(answer, 'title["en-GB"]')}
                      {' '}
                      |
                      {' '}
                      {answer.id}
                    </h2>
                    <LocalizedField
                      name={`answers.${index}.title`}
                      field={answer.title}
                      onChange={updateField}
                    />
                    <LocalizedField
                      name={`answers.${index}.description`}
                      field={answer.description}
                      onChange={updateField}
                    />
                    <hr className="divider" />
                  </div>
                ))}
              </>
            )
            : null
        }
      </section>
      <FieldAddModal
        visible={visible}
        hideModal={() => setVisible(false)}
        onSave={addField}
      />
    </>
  );
};

export default DecisionAidQuestion;
