import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as IconAccount } from '../assets/icons/Account.svg';
import Colors from '../theme/Colors';
import Drawer from './Drawer';
import { AppState } from '../state/reducers';
import { selectors as userSelectors } from '../state/user';
import Button from './Button';
import { useLogOut } from '../utils/hooks';

const DrawerRight = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => userSelectors.getUserCognito(state));
  const logOut = useLogOut(dispatch);

  return (
    <Drawer position="right">
      <section className="container">
        <section>
          <h1 className="heading-large accent">User</h1>
          <header className="drawer-header row start center mt10">
            <IconAccount fill={Colors.primary} className="icon" />
            <p className="user-title">{user}</p>
          </header>
        </section>
        <section className="upload-container">
          <Button buttonType="button" labelText="Log out" onClick={logOut} />
        </section>
      </section>
    </Drawer>
  );
};

export default DrawerRight;
