/* eslint-disable import/prefer-default-export */
import * as actionTypes from './actionTypes';
import { AuthActionTypes } from './types';

const userCognito = (payload: string): AuthActionTypes => ({
  type: actionTypes.USER_COGNITO,
  payload,
});
const userGitHub = (payload: string): AuthActionTypes => ({
  type: actionTypes.USER_GITHUB,
  payload,
});

export { userCognito, userGitHub };
