import React, { useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import Dropdown from 'react-dropdown';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import filter from 'lodash/filter';
import { AppState } from '../state/reducers';
import { TProfileQuestion } from '../state/systemDocument/types';

import ProfileQuestion from '../components/ProfileQuestion';
import { selectors as systemDocumentSelectors } from '../state/systemDocument';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

const ProfileQuestions = () => {
  const [selectedId, setSelectedId] = useState('');

  const questions = filter(useSelector(
    (state: AppState) => systemDocumentSelectors.getProfileQuestions(state),
  ), (item) => !item.SHOULD_BE_REPLACED_WITH_CURRENT); // filter for BC app sys doc (remove "_comment" section). TODO filter should be removed after going life with AZ

  const dropdownOptions = map(questions, (item) => ({
    value: item.id,
    label: item.title['en-GB'],
  })).filter((question) => question.value !== '_comment');

  return (
    <>
      <section className="page">
        <DrawerButtons title="Profile questions" showRightButton />
        <h2 className="heading-secondary">Select profile question</h2>
        <Dropdown
          options={dropdownOptions}
          onChange={(option) => setSelectedId(option.value)}
          value={selectedId}
          placeholder="Select profile question"
        />
        {
        !isEmpty(selectedId) && !isEmpty(get(questions, selectedId))
          ? (
            <ProfileQuestion
              question={get(questions, selectedId) as TProfileQuestion}
              key={selectedId}
            />
          )
          : null
      }
      </section>
      <DrawerRight />
    </>
  );
};

export default ProfileQuestions;
