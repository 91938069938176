import React, { useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import without from 'lodash/without';
import { useSelector } from 'react-redux';

import { AppState } from '../state/reducers';

import { selectors as systemDocumentSelectors } from '../state/systemDocument';

import Modal from './Modal';
import CheckboxGroup from './CheckboxGroup';

type Props = {
  visible: boolean;
  hideModal: () => void;
  onSave: (values: string[]) => void;
  profileQuestionIds: string[];
};

const ProfileQuestionAddModal = (props: Props) => {
  const {
    visible,
    hideModal,
    onSave,
    profileQuestionIds,
  } = props;

  const [values, setValues] = useState(profileQuestionIds);

  const profileQuestions = useSelector(
    (state: AppState) => systemDocumentSelectors.getProfileQuestions(state),
  );

  const options = map(profileQuestions, (question) => ({
    value: question.id,
    labelText: get(question, 'title["en-GB"]'),
  }));

  const handleSave = () => {
    onSave(values);

    setValues([]);
  };

  const handleChange = (value: string, checked: boolean) => {
    if (checked) {
      return setValues(without(values, value));
    }

    return setValues(union(values, [value]));
  };

  return (
    <Modal
      visible={visible}
      hideModal={hideModal}
      actions={[
        {
          title: 'Cancel',
          onClick: hideModal,
        },
        {
          title: 'Save',
          onClick: handleSave,
          primary: true,
          disabled: isEmpty(values),
        },
      ]}
    >
      <h1 className="heading">Add questions</h1>
      <CheckboxGroup
        values={values}
        onChange={handleChange}
        options={options}
      />
    </Modal>
  );
};

export default ProfileQuestionAddModal;
