import React, { useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import assignIn from 'lodash/assignIn';
import union from 'lodash/union';
import map from 'lodash/map';
import first from 'lodash/first';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../state/reducers';

import { TProfileQuestion } from '../state/systemDocument/types';
import LocalizedField from './LocalizedField';
import FieldAddModal from './FieldAddModal';
import Field from './Field';
import DropdownField from './DropdownField';
import TagAddModal from './TagAddModal';
import { ReactComponent as IconAdd } from '../assets/icons/Add.svg';
import { actions as systemDocumentActions, selectors as systemDocumentSelectors } from '../state/systemDocument';

import Colors from '../theme/Colors';

type Props = {
  question: TProfileQuestion;
};

const ProfileQuestion = (props: Props) => {
  const { question } = props;

  const dispatch = useDispatch();

  const profileQuestions = useSelector(
    (state: AppState) => systemDocumentSelectors.getProfileQuestions(state),
  );

  const answerTypes = uniqBy(
    map(profileQuestions, (item) => ({
      value: item.answerType,
      label: item.answerType,
    })),
    'value',
  );

  const [visible, setVisible] = useState(false);
  const [tagVisible, setTagVisible] = useState(false);

  const updateField = (path: string, value: any) => {
    const updatedObject = set(question, path, value);

    dispatch(systemDocumentActions.updateProfileQuestions(updatedObject));
  };

  const addField = (field: any) => {
    dispatch(systemDocumentActions.updateProfileQuestions(assignIn(question, field)));

    setVisible(false);
  };

  const addTag = (value: string) => {
    // TODO allow adding nested tags
    if (isArray(first(question.tags as string[]))) {
      return false;
    }

    const tags = union(question.tags as string[], [value]);

    dispatch(systemDocumentActions.updateProfileQuestions(set(question, 'tags', tags)));

    return setTagVisible(false);
  };

  return (
    <>
      <section className="trend-indicator">
        <h1 className="heading">
          {get(question, 'title["en-GB"]')}
          {' '}
          |
          {' '}
          {question.id}
        </h1>
        <button type="button" className="button contrast add-field" onClick={() => setVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add field
        </button>
        <LocalizedField
          name="title"
          field={question.title}
          onChange={updateField}
        />
        {
          question.subTitle
            ? (
              <LocalizedField
                name="subTitle"
                field={question.subTitle}
                onChange={updateField}
              />
            )
            : null
        }
        <LocalizedField
          name="description"
          field={question.description}
          onChange={updateField}
          textarea
        />
        <DropdownField
          path="answerType"
          value={question.answerType}
          onChange={updateField}
          options={answerTypes}
        />
        <Field
          path="label"
          value={question.label}
          onChange={updateField}
        />
        {map(question.tags, (tag, index) => {
          if (isArray(tag)) {
            return map(tag, (t, tIndex) => (
              <Field
                key={`tags.${index}.${tIndex}`}
                path={`tags.${index}.${tIndex}`}
                value={t}
                onChange={updateField}
              />
            ));
          }

          return (
            <Field
              key={`tags.${index}`}
              path={`tags.${index}`}
              value={tag}
              onChange={updateField}
            />
          );
        })}
        {
          !isArray(first(question.tags as string[])) // TODO allow adding nested tags
            ? (
              <button type="button" className="button contrast add-field" onClick={() => setTagVisible(true)}>
                <IconAdd fill={Colors.white} className="icon" />
                Add tag
              </button>
            )
            : null
        }
        {
          !isEmpty(question.answers)
            ? (
              <>
                <h1 className="heading">Answer options</h1>
                {map(question.answers, (answer, index) => (
                  <div key={`answer.${index}.title`} className="option">
                    <h2 className="heading-secondary">
                      {get(answer, 'title["en-GB"]')}
                      {' '}
                      |
                      {' '}
                      {answer.id}
                    </h2>
                    <LocalizedField
                      name={`answers.${index}.title`}
                      field={answer.title}
                      onChange={updateField}
                    />
                    <LocalizedField
                      name={`answers.${index}.description`}
                      field={answer.description}
                      onChange={updateField}
                    />
                    <Field
                      path={`answers.${index}.label`}
                      value={answer.label}
                      onChange={updateField}
                    />
                    <hr className="divider" />
                  </div>
                ))}
              </>
            )
            : null
        }
      </section>
      <FieldAddModal
        visible={visible}
        hideModal={() => setVisible(false)}
        onSave={addField}
      />
      <TagAddModal
        visible={tagVisible}
        hideModal={() => setTagVisible(false)}
        onSave={addTag}
      />
    </>
  );
};

export default ProfileQuestion;
