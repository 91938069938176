/* eslint react/require-default-props: 0 */
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import '../css/ListItem.css';

type Props = {
  primaryText: ReactNode;
  primaryTextClassName?: string;
  secondaryText?: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onClick?: () => void;
  divider?: boolean;
  dividerOffset?: number;
  to?: string;
  active?: boolean;
  disabled?: boolean;
  className?: string;
};

const ListItem = (props: Props) => {
  const {
    primaryText,
    primaryTextClassName = 'button',
    secondaryText = null,
    leftIcon = null,
    rightIcon = null,
    onClick,
    divider,
    dividerOffset,
    to = '',
    active = false,
    disabled = false,
    className = '',
  } = props;

  const dividerStyle = {
    width: `calc(100% - ${dividerOffset}px)`,
    margin: `0 0 0 ${dividerOffset}px`,
  };

  const children = (
    <>
      <section className="list-item-row">
        {
          leftIcon
            ? <div className="icon-left">{leftIcon}</div>
            : null
        }
        <section>
          <h1 className={primaryTextClassName}>{primaryText}</h1>
          {secondaryText ? <p className="body light">{secondaryText}</p> : null}
        </section>
        {
          rightIcon
            ? <div className="icon-right">{rightIcon}</div>
            : null
        }
      </section>
      {divider ? <hr className="divider" style={dividerStyle} /> : null}
    </>
  );

  const containerClassName = classNames('list-item', className, { active });

  if (onClick) {
    return <button onClick={onClick} type="button" className={containerClassName} disabled={disabled}>{children}</button>;
  }

  if (to) {
    return <Link to={to} className={containerClassName}>{children}</Link>;
  }

  return <section className={containerClassName}>{children}</section>;
};

export default ListItem;
