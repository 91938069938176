import { createStore, applyMiddleware } from 'redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/es/storage';

import rootReducer from '../state/reducers';
import rootSaga from '../sagas';

const DEV = process.env.NODE_ENV !== 'production';

const configureStore = () => {
  const migrations = {};

  const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    timeout: 10000,
    stateReconciler: autoMergeLevel2,
    whitelist: ['user'],
    migrate: createMigrate(migrations, { debug: DEV }),
  };

  const sagaMiddleware = createSagaMiddleware();

  const persistedReducer = persistReducer(persistConfig, rootReducer as any); // TSC: correctly fix type error

  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware),
    ),
  );

  const persistor = persistStore(store);

  // Run root saga
  const sagaTask = sagaMiddleware.run(rootSaga);

  return { store, persistor, sagaTask };
};

export default configureStore;
