import React, { useCallback, useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import toast from 'react-hot-toast';
import { AppState } from '../state/reducers';
import getUserInfo from '../utils/getUserInfo';
import { selectors as userSelectors } from '../state/user';
import GitHubAuth from './GitHubAuth';

const Login = () => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const isCognitoLoggedIn = sessionStorage.getItem('is_cognito_logged_in') === 'true';
  const isScreenVisible = sessionStorage.getItem('is_cognito_logged_in') !== null || code !== null;
  const cognitoUser = useSelector((state: AppState) => userSelectors.getUserCognito(state));

  const handleCognitoAuth = useCallback(async () => {
    if (code && !isCognitoLoggedIn) {
      try {
        const tokenResponse = await fetch(`https://${process.env.REACT_APP_COGNITO_DOMAIN_NAME}/oauth2/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: `${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}`,
            code,
            redirect_uri: `${process.env.REACT_APP_COGNITO_CALLBACK_URL}`,
          }),
        });

        const tokenData = await tokenResponse.json();
        const { access_token: accessToken, expires_in: expires } = tokenData;

        if (accessToken) {
          const expiredIn = new Date(Date.now() + expires * 1000).toUTCString();
          sessionStorage.setItem('is_cognito_logged_in', 'true');
          sessionStorage.setItem('expired_in', expiredIn);
          await getUserInfo(dispatch, accessToken);
        }
      } catch (error: any) {
        toast.error('Sorry, failed to fetch tokens. Try again later please.');
        throw new Error(error);
      }
    } else {
      const isLoggedIn = sessionStorage.getItem('is_cognito_logged_in');
      if (isLoggedIn === null) {
        window.location.href = `https://${process.env.REACT_APP_COGNITO_DOMAIN_NAME}/login?response_type=code&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COGNITO_CALLBACK_URL}`;
      }
    }
  }, [code, dispatch, isCognitoLoggedIn]);

  // TODO: Remove after testing if no extra cognito login appears
  // if (!isScreenVisible) {
  //   window.location.href = `https://${process.env.REACT_APP_COGNITO_DOMAIN_NAME}/login?response_type=code&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COGNITO_CALLBACK_URL}`;
  // }

  useEffect(() => {
    handleCognitoAuth();
  }, [handleCognitoAuth]);

  return (
    isScreenVisible ? (
      <section>
        {cognitoUser !== '' ? <GitHubAuth /> : null}
      </section>
    ) : null
  );
};

export default Login;
