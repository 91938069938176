const JSONToBase64 = (obj: object) => {
  try {
    // Convert JSON object to a pretty-printed JSON string
    const prettyJsonString = JSON.stringify(obj, null, 2);

    // Encode the pretty-printed JSON string to Base64
    const encoder = new TextEncoder();
    const data = encoder.encode(prettyJsonString);

    let base64String = '';
    for (let i = 0; i < data.length; i += 1) {
      base64String += String.fromCharCode(data[i]);
    }

    return btoa(base64String);
  } catch (e) {
    throw new Error();
  }
};

export default JSONToBase64;
