import React, { useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import assignIn from 'lodash/assignIn';
import union from 'lodash/union';
import { useDispatch } from 'react-redux';

import { TTreatmentType } from '../state/systemDocument/types';
import LocalizedField from './LocalizedField';
import Field from './Field';
import DropdownField from './DropdownField';
import ColorField from './ColorField';
import FieldAddModal from './FieldAddModal';
import TagAddModal from './TagAddModal';
import { ReactComponent as IconAdd } from '../assets/icons/Add.svg';
import { actions as systemDocumentActions } from '../state/systemDocument';

import Colors from '../theme/Colors';
import { useSaveChecker } from '../utils/hooks';

const subTypes = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'text',
    label: 'Text',
  },
  {
    value: 'option',
    label: 'Option',
  },
];

type Props = {
  treatmentType: TTreatmentType;
};

const TreatmentType = (props: Props) => {
  const { treatmentType } = props;

  const dispatch = useDispatch();
  const checkSave = useSaveChecker();

  const [visible, setVisible] = useState(false);
  const [tagVisible, setTagVisible] = useState(false);
  const [optionTagPath, setOptionTagPath] = useState('');

  const updateField = (path: string, value: any) => {
    const updatedObject = set(treatmentType, path, value);

    dispatch(systemDocumentActions.updateTreatmentTypes(updatedObject));
  };

  const addField = async (field: any) => {
    dispatch(systemDocumentActions.updateTreatmentTypes(assignIn(treatmentType, field)));

    setVisible(false);
  };

  const addTag = async (value: string) => {
    const tags = union(treatmentType.tags, [value]);

    dispatch(systemDocumentActions.updateTreatmentTypes(set(treatmentType, 'tags', tags)));

    setTagVisible(false);
  };

  const addOptionTag = async (value: string) => {
    const tags = union(get(treatmentType, optionTagPath), [value]);

    dispatch(systemDocumentActions.updateTreatmentTypes(set(treatmentType, optionTagPath, tags)));

    setOptionTagPath('');
  };

  return (
    <>
      <section className="trend-indicator">
        <h1 className="heading">
          {get(treatmentType, 'title["en-GB"]')}
          {' '}
          |
          {' '}
          {treatmentType.id}
        </h1>
        <button type="button" className="button contrast add-field" onClick={() => setVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add field
        </button>
        <LocalizedField
          name="title"
          field={treatmentType.title}
          onChange={updateField}
        />
        <ColorField
          path="color"
          value={treatmentType.color}
          onChange={updateField}
        />
        <DropdownField
          path="subType"
          value={treatmentType.subType}
          onChange={updateField}
          options={subTypes}
        />
        <Field
          path="order"
          value={treatmentType.order}
          onChange={updateField}
        />
        {map(treatmentType.tags, (tag, index) => (
          <Field
            key={`tags.${index}`}
            path={`tags.${index}`}
            value={tag}
            onChange={updateField}
          />
        ))}
        <button type="button" className="button contrast add-field" onClick={() => setTagVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add tag
        </button>
        {
          treatmentType.subType === 'option' && !isEmpty(treatmentType.options)
            ? (
              <>
                <h1 className="heading">Treatment options</h1>
                {map(treatmentType.options, (option, index) => (
                  <div key={`options.${index}.title`} className="option">
                    <h2 className="heading-secondary">
                      {get(option, 'title["en-GB"]')}
                      {' '}
                      |
                      {' '}
                      {option.id}
                    </h2>
                    <LocalizedField
                      name={`options.${index}.title`}
                      field={option.title}
                      onChange={updateField}
                    />
                    {map(option.tags, (tag, tagIndex) => (
                      <Field
                        key={`options.${index}.tags.${tagIndex}`}
                        path={`options.${index}.tags.${tagIndex}`}
                        value={tag}
                        onChange={updateField}
                      />
                    ))}
                    {map(option?.dosages, (tag, tagIndex) => (
                      <LocalizedField
                        key={`options.${index}.dosages.${tagIndex}`}
                        name={`options.${index}.dosages.title.${tagIndex}`}
                        field={tag.title}
                        onChange={updateField}
                      />
                    ))}
                    <button type="button" className="button contrast add-field" onClick={() => setOptionTagPath(`options.${index}.tags`)}>
                      <IconAdd fill={Colors.white} className="icon" />
                      Add tag
                    </button>
                    <hr className="divider" />
                  </div>
                ))}
              </>
            )
            : null
        }
      </section>
      <FieldAddModal
        visible={visible}
        hideModal={() => setVisible(false)}
        onSave={async (...args) => checkSave(addField.bind(this, ...args))}
      />
      <TagAddModal
        visible={tagVisible}
        hideModal={() => setTagVisible(false)}
        onSave={async (...args) => checkSave(addTag.bind(this, ...args))}
      />
      <TagAddModal
        visible={!isEmpty(optionTagPath)}
        hideModal={() => setOptionTagPath('')}
        onSave={async (...args) => checkSave(addOptionTag.bind(this, ...args))}
      />
    </>
  );
};

export default TreatmentType;
