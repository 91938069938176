import React, { useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import Dropdown from 'react-dropdown';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { AppState } from '../state/reducers';
import { TDecisionAidQuestion } from '../state/systemDocument/types';

import DecisionAidQuestion from '../components/DecisionAidQuestion';
import { selectors as systemDocumentSelectors } from '../state/systemDocument';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

const DecisionAidQuestions = () => {
  const [selectedId, setSelectedId] = useState('');

  const questions = useSelector(
    (state: AppState) => systemDocumentSelectors.getDecisionAidQuestions(state),
  );

  const dropdownOptions = map(questions, (item) => ({
    value: item.id,
    label: item.title['en-GB'],
  }));

  return (
    <>
      <section className="page">
        <DrawerButtons title="Decision aid questions" showRightButton />
        <h2 className="heading-secondary">Select decision aid question</h2>
        <Dropdown
          options={dropdownOptions}
          onChange={(option) => setSelectedId(option.value)}
          value={selectedId}
          placeholder="Select decision aid question"
        />
        {
        !isEmpty(selectedId) && !isEmpty(get(questions, selectedId))
          ? (
            <DecisionAidQuestion
              question={get(questions, selectedId) as TDecisionAidQuestion}
              key={selectedId}
            />
          )
          : null
      }
      </section>
      <DrawerRight />
    </>
  );
};

export default DecisionAidQuestions;
