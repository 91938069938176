import React, { useState } from 'react';

import Modal from './Modal';

type Props = {
  visible: boolean;
  hideModal: () => void;
  onSave: (field: any) => void;
};

const TagAddModal = (props: Props) => {
  const { visible, hideModal, onSave } = props;

  const [value, setValue] = useState('');

  const handleSave = () => {
    onSave(value);

    setValue('');
  };

  return (
    <Modal
      visible={visible}
      hideModal={hideModal}
      actions={[
        {
          title: 'Cancel',
          onClick: hideModal,
        },
        {
          title: 'Save',
          onClick: handleSave,
          primary: true,
          disabled: !value,
        },
      ]}
    >
      <h1 className="heading">Add tag</h1>
      <label htmlFor="title">
        <span className="body light">Value</span>
        <input
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
          name="title"
          id="title"
        />
      </label>
    </Modal>
  );
};

export default TagAddModal;
