import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import 'reset-css';
import 'react-dropdown/style.css';

import './css/index.css';
import './css/Text.css';
import Router from './Router';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';

const { store, persistor } = configureStore();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router />
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
