import React, { useState, FormEvent } from 'react';

import toast from 'react-hot-toast';
import Button from '../components/Button';
import { useOctokit } from '../utils/hooks';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

const Upload = () => {
  const [ticketNumber, setTicketNumber] = useState('');
  const [commit, setCommit] = useState('');
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { uploadSystemDocumentAsync } = useOctokit();

  const handleUploadAsync = async (event: FormEvent) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      await toast.promise(
        uploadSystemDocumentAsync(ticketNumber, commit, comment),
        {
          loading: 'Uploading...',
          success: () => <b>Success</b>,
          error: (error: Error) => error.message
              || <b>Something went wrong, please try again later.</b>,
        },
        {
          id: 'uploading',
        },
      );

      setIsLoading(false);
    } catch (e: any) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(`[uploadSystemDocumentAsync] ${e.message || 'upload system document request failed'}`);
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <section className="page">
        <DrawerButtons title="Blogs" showRightButton />
        <form className="upload" onSubmit={handleUploadAsync}>
          <h1 className="heading">Upload system document</h1>
          <label htmlFor="ticketNumber" key="ticketNumber">
            <span className="body light">Jira ticket number</span>
            <input
              value={ticketNumber}
              onChange={(event) => setTicketNumber(event.currentTarget.value)}
              name="ticketNumber"
              id="ticketNumber"
              type="text"
            />
          </label>
          <label htmlFor="commit" key="commit">
            <span className="body light">Commit title</span>
            <input
              value={commit}
              onChange={(event) => setCommit(event.currentTarget.value)}
              name="commit"
              id="commit"
              type="text"
            />
          </label>
          <label htmlFor="comment" key="comment">
            <span className="body light">Comment message</span>
            <input
              value={comment}
              onChange={(event) => setComment(event.currentTarget.value)}
              name="comment"
              id="comment"
              type="text"
            />
          </label>
          <Button
            labelText="Upload"
            disabled={isLoading}
            buttonType="submit"
          />
        </form>
      </section>
      <DrawerRight />
    </>
  );
};

export default Upload;
