/*
 */
const base64ToJSON = (base64String: string) => {
  try {
    const data = atob(base64String);

    // btoa and atob are designed to work with ASCII data, and they can have issues with characters outside of the ASCII range.
    // To handle non-ASCII characters correctly, we should use TextDecoder
    const decoder = new TextDecoder('utf-8');
    const jsonString = decoder.decode(
      new Uint8Array(data.split('').map((c) => c.charCodeAt(0))),
    );

    return JSON.parse(jsonString);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('erer', e);
    throw new Error();
  }
};

export default base64ToJSON;
