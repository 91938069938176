/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { actions as uiActions } from '../state/ui';
import { ReactComponent as IconMenuLeft } from '../assets/icons/MenuLeft.svg';
import { ReactComponent as IconMenuRight } from '../assets/icons/MenuRight.svg';
import Colors from '../theme/Colors';

const propTypes = {
  title: PropTypes.node,
  showRightButton: PropTypes.bool,
};

type Props = {
  title?: ReactNode;
  titleColor?: string,
  showRightButton?: boolean;
};

const DrawerButtons = (props: Props) => {
  const { title, showRightButton = false, titleColor } = props;

  const dispatch = useDispatch();

  const toggleDrawerLeft = () => dispatch(uiActions.toggleDrawerLeft());
  const toggleDrawerRight = () => dispatch(uiActions.toggleDrawerRight());

  return (
    <header className="drawer-buttons">
      <button type="button" className="drawer-button left icon-button" onClick={toggleDrawerLeft}>
        <IconMenuLeft fill={Colors.black} className="icon" />
      </button>
      {title ? <h1 className="heading-large accent" style={{ color: titleColor }}>{title}</h1> : null}
      {
        showRightButton && (
          <button type="button" className="drawer-button right icon-button" onClick={toggleDrawerRight}>
            <div className="unread">
              <IconMenuRight fill={Colors.black} className="icon" />
            </div>
          </button>
        )
      }
    </header>
  );
};

DrawerButtons.propTypes = propTypes;

export default DrawerButtons;
