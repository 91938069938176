import { combineReducers } from 'redux';

import auth from '../auth/reducer';
import systemDocument from '../systemDocument/reducer';
import settings from '../settings/reducer';
import ui from '../ui/reducer';
import user from '../user/reducer';

const reducer = combineReducers({
  auth,
  settings,
  systemDocument,
  ui,
  user,
});

export type AppState = ReturnType<typeof reducer>;

export default reducer;
