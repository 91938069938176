import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconChevronUp } from '../assets/icons/ChevronUp.svg';
import { ReactComponent as IconChevronDown } from '../assets/icons/ChevronDown.svg';
import Button from './Button';
import Colors from '../theme/Colors';
import DialogModal from './DialogModal';

type Props = {
  word: {
    word: string;
    description: string;
  };
  index: number;
  updateWord: (index: number, word: string, description: string) => void;
  removeWord: (index: number) => void;
};

const propTypes = {
  word: PropTypes.shape({
    word: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  updateWord: PropTypes.func.isRequired,
  removeWord: PropTypes.func.isRequired,
};

const Word = (props: Props) => {
  const {
    word, index, updateWord, removeWord,
  } = props;

  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState({
    word: word.word,
    description: word.description,
  });

  useEffect(() => {
    if (word.word !== state.word || word.description !== state.description) {
      setState({
        word: word.word,
        description: word.description,
      });
    }
  }, [word]);

  const handleUpdate = (field: string, value: string) => (
    setState({
      ...state,
      [field]: value,
    })
  );

  const handleSave = () => updateWord(index, state.word, state.description);

  const handleDelete = () => {
    removeWord(index);
    setShowModal(false);
  };

  return (
    <section className="field">
      <button type="button" onClick={() => setVisible(!visible)}>
        <h2 className="heading-secondary">{word.word}</h2>
        {
          visible
            ? <IconChevronUp fill={Colors.lightGray} className="icon small" />
            : <IconChevronDown fill={Colors.lightGray} className="icon small" />
        }
      </button>
      {
        visible
          ? (
            <>
              <label htmlFor="title" key="title">
                <span className="body light">Word</span>
                <input
                  value={state.word}
                  onChange={(event) => handleUpdate('word', event.currentTarget.value)}
                  name="title"
                  id="title"
                />
              </label>
              <label htmlFor="body" key="body">
                <span className="body light">Description</span>
                <textarea
                  value={state.description}
                  onChange={(event) => handleUpdate('description', event.currentTarget.value)}
                  name="body"
                  id="body"
                />
              </label>
              <Button labelText="Save" buttonType="submit" size="medium" onClick={handleSave} />
              <Button className="mh10" color="red" labelText="Delete" buttonType="submit" size="medium" onClick={() => setShowModal(true)} />
            </>
          )
          : null
        }
      {showModal ? (
        <DialogModal
          title="Are you sure you want to delete this word?"
          visible={showModal}
          hideModal={() => setShowModal(false)}
          positiveAction={handleDelete}
        />
      ) : null}
    </section>
  );
};

Word.propTypes = propTypes;

export default Word;
